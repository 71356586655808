import React, { Fragment } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";

const MobileImage = styled(GatsbyImage)`
  display: block;
  @media (min-width: ${breakpoints.md}) {
    display: none !important;
  }
`;
const DesktopImage = styled(GatsbyImage)`
  display: none !important;
  @media (min-width: ${breakpoints.md}) {
    display: block !important;
  }
`;

const Image = ({ image, imageMobile }) => {
  return (
    <Fragment>
      <MobileImage
        image={
          imageMobile ? imageMobile.gatsbyImageData : image.gatsbyImageData
        }
        alt={imageMobile ? imageMobile.description : image.description}
      />
      <DesktopImage image={image.gatsbyImageData} alt={image.description} />
    </Fragment>
  );
};

export default Image;
